<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Lähtevät maksut</div>

    <v-row dense>
      <v-col>
        <v-btn-toggle mandatory color="blue" v-model="page">
          <v-btn value="payments"> Maksut </v-btn>
          <v-btn value="checkList"> Yhteenveto </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- Payments -->
    <v-container v-if="page == 'payments'" fluid style="padding: 0px; margin: 0px">
      <v-tabs
        v-model="tab"
        flat
        color="primary"
        style="margin: 0px; padding: 0px"
        fixed-tabs
        show-arrows
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in tabs" :key="'a' + index">
          <div>
            <span>
              {{ item }}
            </span>
          </div>
        </v-tab>
      </v-tabs>
    </v-container>

    <!-- Outgoing payments table -->
    <outgoing-payment-table
      v-if="page === 'payments' && tab != 2"
      :tab="tab"
    ></outgoing-payment-table>

    <!-- Other payments table -->
    <other-payment-table v-if="page === 'payments' && tab == 2"></other-payment-table>

    <!-- Check List -->
    <check-list v-if="page === 'checkList'"></check-list>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import OutgoingPaymentTable from "../../components/OutgoingPayments/OutgoingPaymentTable.vue";
import OtherPaymentTable from "../../components/OutgoingPayments/OtherPaymentTable.vue";
import CheckList from "../../components/OutgoingPayments/CheckList.vue";

export default {
  title: "Lähtevät maksut",

  components: {
    OutgoingPaymentTable,
    OtherPaymentTable,
    CheckList,
  },

  mixins: [mixins],

  data: () => ({
    page: "payments",
    tab: 0,
    tabs: ["Maksut", "Kulut", "Kohdistumattomat"],
  }),
};
</script>

<style scoped></style>
