<template>
  <v-card>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Saaja, maksuviite, summa..."
            single-line
            hide-details
            solo
            @keyup.enter="getDataFromApi()"
            @click:append="getDataFromApi()"
          ></v-text-field>
        </v-col>
      </v-row>
      <div style="max-width: 360px">
        <v-checkbox
          v-model="showUpdatedPayments"
          dense
          label="Näytä käsitellyt"
          hide-details
          @change="getDataFromApi"
        ></v-checkbox>
      </div>
    </v-container>

    <v-data-table
      :headers="filteredHeaders"
      :items="otherPayments"
      :footer-props="globalValues.footerProps"
      :items-per-page="getDefaultTableRows()"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalLength"
    >
      <!-- CreatedAt-->
      <template #[`item.createdAt`]="{ item }">
        <span>{{ formatDate(item.createdAt) }}</span>
      </template>

      <!-- Payment date-->
      <template #[`item.paymentDate`]="{ item }">
        <span>{{ formatDate(item.paymentDate) }} </span>
      </template>

      <!--  Amount -->
      <template #[`item.amount`]="{ item }">
        <span class="font-weight-bold error--text">{{ formatCurrency(item.amount) }}</span>
      </template>

      <!-- Joined to-->
      <template #[`item.joinedTo`]="{ item }">
        <v-tooltip v-if="item.joinedTo" top max-width="400">
          <template #activator="{ on, attrs }">
            <div class="primary--text" style="cursor: pointer" v-bind="attrs" v-on="on">
              <span> # {{ item.joinedTo.paymentNumber }} </span>
            </div>
          </template>

          <h4 class="mb-1">Kohdistetut maksut</h4>

          <p style="margin-bottom: 6px; font-size: 12px">
            {{ formatDateMonthName(item.joinedTo.targetMonth) }}
          </p>
          <p>
            {{ item.joinedTo.paymentType == "payment" ? "Maksu" : "Kulu" }}: #
            {{ item.joinedTo.paymentNumber }}
          </p>
        </v-tooltip>
        <span v-else></span>
      </template>

      <!-- Payment Reference-->
      <template #[`item.paymentReference`]="{ item }">
        <span>{{ item.paymentReference }}</span>
      </template>

      <!-- Actions -->
      <template #[`item.actions`]="{ item }">
        <div class="action-grid-container">
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'edit')"
            title="Muokkaa"
            small
            @click="
              $store.state.outgoingpayment.currentOtherPayment = { ...item };
              editPaymentDialog = true;
            "
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'delete') && !item.joinedTo"
            title="Poista"
            small
            @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </div>
      </template>

      <template #no-data>
        <h2>Ei kohdistumattomia suorituksia</h2>
      </template>
    </v-data-table>

    <!-- Edit other Payment -->
    <edit-other-payment
      v-model="editPaymentDialog"
      :edited="showUpdatedPayments"
    ></edit-other-payment>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import EditOtherPayment from "../../components/OutgoingPayments/EditOtherPayment.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Lähtevät maksut",

  components: { EditOtherPayment },

  mixins: [mixins],

  data: () => ({
    showUpdatedPayments: false,
    tab: 0,
    tabs: ["Maksut", "Kulut", "Kohdistumattomat"],
    loading: true,
    options: {},
    dialog: false,
    editPaymentDialog: false,
    search: "",
    headers: [
      { text: "Saaja", value: "name" },
      { text: "Luotu", value: "createdAt" },
      { text: "Maksupäivä", value: "paymentDate" },
      { text: "Maksuviite", value: "reference" },
      { text: "Kohdistettu", value: "joinedTo" },
      { text: "Kommentti", value: "comment" },
      { text: "Summa", value: "amount" },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("outgoingpayment", ["otherPayments", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    filteredHeaders() {
      if (this.showUpdatedPayments) return this.headers;
      else return this.headers.filter((el) => el.value !== "comment" && el.value !== "joinedTo");
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("outgoingpayment", ["getOtherPayments", "deleteOtherPayment"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      const showUpdatedPayments = this.showUpdatedPayments;

      let url = `/api/v1/outgoing-payment/get-other-payments?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}&showUpdatedPayments=${showUpdatedPayments}`;

      await this.getOtherPayments(url);

      this.loading = false;
    },

    editItem(item) {
      this.$router.push("/outgoing-payment/edit-other/" + item._id);
    },

    deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa maksun: " + item.name))
        this.deleteOtherPayment(item._id);
    },
  },
};
</script>

<style scoped></style>
