var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('search-by-dates',{ref:"searchByDates",attrs:{"showSearchBy":false,"checkStartDate":_vm.allowedStartDatesFunction,"checkEndDate":_vm.allowedEndDatesFunction,"startDateRequired":true,"endDateRequired":true}}),_c('v-btn',{attrs:{"color":"primary mt-1"},on:{"click":_vm.getDataFromApi}},[_vm._v("Hae")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Osoite tai vuokralainen","single-line":"","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.apartmentId))}},[_c('span',[_vm._v(_vm._s(_vm.getFullAddress(item))+" ")])])]}},{key:"item.owners",fn:function(ref){
var item = ref.item;
return [(item.owners.length == 0)?_c('span',{staticClass:"error--text"},[_vm._v("Ei omistajia")]):_vm._l((item.owners),function(owner,idx){return _c('div',{key:'a' + idx},[(owner.onModel == 'Partner')?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/partner/overview/" + (owner.ownerId))}},[_c('span',[_vm._v(_vm._s(owner.name))])]):_c('span',[_vm._v(_vm._s(owner.name))])],1)})]}},{key:"item.contracts",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.hasValidContract(item) ? 'success' : 'error',"small":""}},[_vm._v("mdi-circle")]),(item.contracts.length == 0)?_c('span',{staticClass:"error--text"},[_vm._v("Ei sopimuksia")]):_c('div',_vm._l((item.contracts),function(c,idx){return _c('div',{key:'a' + idx},[_c('p',[_vm._v(_vm._s(c.tenant)+" (sop. "+_vm._s(c.contractNumber)+")")]),_c('p',[_vm._v(" "+_vm._s(_vm.formatDate(c.startDate))+" "),(c.revokedDate || c.endDate)?_c('span',[_vm._v("- "+_vm._s(c.revokedDate ? _vm.formatDate(c.revokedDate) : _vm.formatDate(c.endDate)))]):_vm._e()])])}),0)],1)]}},{key:"item.assignment",fn:function(ref){
var item = ref.item;
return [(!item.assignment)?_c('span',{staticClass:"error--text"},[_vm._v("Ei toimeksiantosopimusta")]):_c('div',[_c('p',[_vm._v("Alkaa: "+_vm._s(_vm.formatDate(item.assignment.startDate)))]),_c('p',[_vm._v("Päättyy: "+_vm._s(item.assignment.endDate ? _vm.formatDate(item.assignment.endDate) : ""))])])]}},{key:"item.totalAmountInvoices",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.totalAmountInvoices)))])]}},{key:"item.interests",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.totalAmountInvoices - item.productsAmount)))])]}},{key:"item.tenantGoal",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'highlight-border': !_vm.ownerGoalMatchesTenantGoal(item) }},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatCurrency(item.productsAmount)))])])]}},{key:"item.tenantState",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:item.openAmountInvoices === 0 ? 'success--text' : 'error--text'},[_vm._v(_vm._s(_vm.formatCurrency(item.openAmountInvoices)))])]}},{key:"item.ownerGoal",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'highlight-border': !_vm.ownerGoalMatchesTenantGoal(item) }},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatCurrency(_vm.calcOwnerGoal(item))))])])]}},{key:"item.paidToOwner",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(Math.abs(item.paymentsTotal))))])]}},{key:"item.ownerState",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:parseFloat(_vm.calcOwnerState(item).toFixed(1)) === 0 ? 'success--text' : 'error--text'},[_c('span',[_vm._v(_vm._s(parseFloat(_vm.calcOwnerState(item).toFixed(1)) < 0 ? "Ylisuoritus" : parseFloat(_vm.calcOwnerState(item).toFixed(1)) > 0 ? "Maksetaan" : ""))]),_vm._v(" "+_vm._s(_vm.formatCurrency(Math.abs(_vm.calcOwnerState(item)))))])]}},{key:"item.receiptsTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(Math.abs(item.receiptsTotal))))])]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei sopimuksia valitulla aikavälillä")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }