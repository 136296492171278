<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>Muokkaa suoritusta {{ currentOtherPayment.name }}</v-card-title>

      <v-card-text v-if="currentOtherPayment.joinedTo">
        <h4 class="success--text">
          Suoritus on jo kohdistettu maksuun # {{ currentOtherPayment.joinedTo.paymentNumber }}
        </h4>
        <p>Poista suoritus tarvittaessa kohdistetun maksun kautta.</p>
      </v-card-text>

      <v-card-text>
        <v-form ref="form">
          <v-checkbox
            v-if="edited && !currentOtherPayment.joinedTo"
            label="Palauta käsittelemättömäksi"
            v-model="returnUnEdited"
          ></v-checkbox>

          <div v-if="!currentOtherPayment.joinedTo && !returnUnEdited">
            <h4>Kohdista lähtevään maksuun</h4>
            <v-row dense>
              <v-col cols="12" sm="8">
                <small>K = Kulu, M = Maksu, V = Viite</small>
                <v-autocomplete
                  v-model="outgoingPayment"
                  :items="activePayments"
                  :item-text="fixedName"
                  item-value="_id"
                  label="Lähtevä maksu"
                  no-data-text="Maksun numero, vuokrakohteen osoite tai viite"
                  outlined
                  small-chips
                  return-object
                  deletable-chips
                  :rules="[atLeastOneRequired]"
                  :search-input.sync="searchInput"
                  @change="searchInput = ''"
                  @input.native="getOutgoingPayment"
                  @blur="$refs.form.validate()"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>

          <div v-if="!returnUnEdited">
            <h4 class="mb-1">Kommentti</h4>
            <v-textarea
              v-model="comment"
              dense
              outlined
              label="Kommentti"
              :rules="[atLeastOneRequired]"
              @blur="$refs.form.validate()"
            ></v-textarea>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="join()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState } from "vuex";
import { debounce } from "../../utils/helpers";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    edited: { type: Boolean, default: false },
  },

  data() {
    return {
      returnUnEdited: false,
      comment: null,
      outgoingInput: "",
      outgoingPayment: null,
      searchInput: "",
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("outgoingpayment", ["activePayments", "currentOtherPayment"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.resetValues();
      }
    },

    currentOtherPayment(val) {
      if (val && val.comment) this.comment = val.comment;
    },

    outgoingInput: debounce(function (newVal) {
      this.searchPayments(`/api/v1/outgoing-payment/search-payment?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("outgoingpayment", ["searchPayments", "joinOtherPayment"]),

    atLeastOneRequired() {
      return (this.comment && this.comment.trim() !== "") ||
        this.outgoingPayment !== null ||
        this.currentOtherPayment.joinedTo
        ? true
        : "Joku kentistä täytyy olla täydennetty";
    },

    getOutgoingPayment(event) {
      this.outgoingInput = event.target.value;
    },

    fixedName(item) {
      if (item.apartment) {
        const number = item.apartment.apartmentNumber ? `, ${item.apartment.apartmentNumber}` : "";
        const paymentType = item.paymentType === "payment" ? "M" : "K";
        const reference = item.paymentReference ? item.paymentReference : "";
        return (
          `${paymentType}=` +
          `${item.paymentNumber}, (${item.apartment.address}${number}) V=${reference}`
        );
      } else {
        return "";
      }
    },

    async join() {
      if (this.$refs.form.validate()) {
        await this.joinOtherPayment({
          ogpId: this.outgoingPayment?._id || null,
          otherPayment: this.currentOtherPayment,
          comment: this.comment,
          returnUnEdited: this.returnUnEdited,
          editList: this.edited,
        });
        this.dialog = false;
      }
    },

    resetValues() {
      this.returnUnEdited = false;
      this.comment = null;
      this.outgoingPayment = null;
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
